import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["homeModal"]

  connect() {
    this.openHomeModal();
  }

  closeHomeModal() {
    localStorage.setItem("homeModal", "closed");
    this.homeModalTarget.classList.add('closed');
    this.homeModalTarget.classList.remove('open');
  }

  openHomeModal() {
    if (localStorage.getItem("homeModal") !== 'closed') {
      localStorage.setItem("homeModal", "open");
      this.homeModalTarget.classList.remove('closed');
      this.homeModalTarget.classList.add('open');
    }
  }
}
